import { createSlice } from '@reduxjs/toolkit';
import { IStatus } from '../../../@types/status';
import { exportData } from './actions';
var initialState = {
    status: IStatus.IDLE,
};
// ----------------------------------------------------------------------
var slice = createSlice({
    name: 'common',
    initialState: initialState,
    reducers: {},
    extraReducers: function (builder) {
        builder
            .addCase(exportData.pending, function (state) {
            state.status = IStatus.LOADING;
        })
            .addCase(exportData.fulfilled, function (state) {
            state.status = IStatus.SUCCEEDED;
        })
            .addCase(exportData.rejected, function (state) {
            state.status = IStatus.FAILED;
        });
    },
});
export default slice.reducer;
